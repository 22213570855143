import Slider from '../components/slider'

const About = {
  init() {
    this.$timeline = document.getElementById('timeline')
    this.timelineWidth = this.$timeline.offsetWidth
    this.clickEvents.call(this)
    Slider.init('about-slide')

    document.querySelectorAll('.video-thumbnail').forEach(el => {
      el.addEventListener('click', (e) => {this.handleVideoClick(el)});
    })
  },
  clickEvents() {
    let $btnNext = document.getElementById('timelineNext')
    let $btnBack = document.getElementById('timelineBack')

    // $btnNext.addEventListener('click', this.handleClick)
    // $btnBack.addEventListener('click', this.handleClick)
  },
  handleClick(e) {
    console.log(e)
    this.$timeline.scrollLeft = e.target.offsetLeft
  },
  handleVideoClick(el) {
    let url = el.getAttribute("data-url");
    let vid = document.getElementById("video-player");
    vid.src = url;
    vid.load();
    vid.play(); 
  }
}

export default About