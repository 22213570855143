class Dropdown {
  constructor(selector) {
    this.$dropdown = document.getElementById(`dropdown${selector}`)
    this.$dropdownBtn = document.getElementById(`dropdownBtn${selector}`)
    this.$dropdownBtn.addEventListener('click', this.handleClick.bind(this))
    // if (this.$dropdownBtn.id == 'dropdownBtnPrompton') {
    //   this.$dropdownBtn.addEventListener('click', this.handleClickPrompton.bind(this))
    // } else {
    //   this.$dropdownBtn.addEventListener('click', this.handleClick.bind(this))
    // }
  }
  handleClick(e) {
    e.preventDefault()
    this.$dropdown.classList.toggle('show')
  }
  handleClickPrompton(e) {
    e.preventDefault()
    this.$dropdown.classList.toggle('showPrompton')
  }
}

export default Dropdown