import '../styles/main.scss'
import handlePath from 'handle-path'

import Header from './components/header'
import Home from './pages/home'
import Commercial from './pages/commercial'
import PublicArt from './pages/public-art'
import InTheCommunity from './pages/in-the-community'
import About from './pages/about'
import Customer from './pages/customer'
import Parking from './pages/parking'
import TenantHomeowner from './pages/tenant-homeowner'
import Communities from './pages/communities'
import Projects from './pages/projects'
import Contact from './pages/contact'
import tippy from 'tippy.js'

const App = {
  init() {
    Header.init()
    this.runPageScript()

    tippy('.wechat-btn', {
      arrow: true,
      placement: 'bottom',
      trigger: 'click',
      html: '#wechatPop',
      theme: 'white'
    })
    document.addEventListener('click', this.documentClick)
  },

  runPageScript() {
    handlePath(window.location.pathname, {
      '/' : () => { Home.init() },
      '/commercial' : () => { Commercial.init() },
      '/public-art/' : () => { PublicArt.init() },
      '/in-the-community/' : () => { InTheCommunity.init },
      '/about/' : () => { About.init() },
      '/customer-care/' : () => { Customer.init() },
      '/parking/' : () => { Parking.init() },
      '/tenant/*' : () => { TenantHomeowner.init() },
      '/homeowner/*' : () => { TenantHomeowner.init() },
      '/communities/' : () => { Communities.init() },
      '/project/:id' : () => { Projects.init() },
      '/contact/' : () => { Contact.init() },

      '/zh' : () => { Home.init() },
      '/zh/commercial' : () => { Commercial.init() },
      '/zh/public-art/' : () => { PublicArt.init() },
      '/zh/in-the-community/' : () => { InTheCommunity.init },
      '/zh/about/' : () => { About.init() },
      '/zh/customer-care/' : () => { Customer.init() },
      '/zh/parking/' : () => { Parking.init() },
      '/zh/tenant/*' : () => { TenantHomeowner.init() },
      '/zh/homeowner/*' : () => { TenantHomeowner.init() },
      '/zh/communities/' : () => { Communities.init() },
      '/zh/project/:id' : () => { Projects.init() },
      '/zh/contact/' : () => { Contact.init() },

      // ['/', '/zh']: () => { Home.init() },
      // ['/commercial', '/zh/commercial']: () => { Commercial.init() },
      // ['/public-art/', '/zh/public-art']: () => { PublicArt.init() },
      // ['/in-the-community/', '/zh/in-the-community/']: () => { InTheCommunity.init },
      // ['/about/', '/zh/about/']: () => { About.init() },
      // ['/customer-care/', '/zh/customer-care/']: () => { Customer.init() },
      // ['/parking/', '/zh/parking/']: () => { Parking.init() },
      // ['/tenant/*', '/zh/tenant/*']: () => { TenantHomeowner.init() },
      // ['/homeowner/*', '/zh/homeowner/*']: () => { TenantHomeowner.init() },
      // ['/communities/', '/zh/communities/']: () => { Communities.init() },
      // ['/project/:id', '/zh/project/:id']: () => { Projects.init() },
    })
  },

  documentClick(e) {
    let target = e.target
    let dropdownContent = document.querySelectorAll('.dropdown-content')

    dropdownContent.forEach(node => {
      target.closest('.dropdown') ? null : node.classList.remove('show')
    })
  }
}
App.init()