export default {
  init(id) {
    new Vue({
      el: `#${id}`,
      delimiters: ['${', '}'],

      data() {
        return {
          slides: [],
          currentSlide: {},
          currentSlideIndex: 0,
        }
      },

      mounted() {
        this.slides = JSON.parse(this.$el.dataset.slides)
        this.currentSlide = this.slides[0]
        this.interval = setInterval(this.updateSlide.bind(this), 4000)
      },

      beforeDestroy() {
        clearInterval(this.interval)
      },

      methods: {
        handleClickPagination(index) {
          this.currentSlideIndex = index
          this.currentSlide = this.slides[index]
        },

        updateSlide() {
          if (this.currentSlideIndex === this.slides.length - 1) {
            this.currentSlideIndex = 0
          } else {
            this.currentSlideIndex++
          }
          this.currentSlide = this.slides[this.currentSlideIndex]
        },
      },
    })
  }
}
