// import $ from 'jquery'

const Slider = {
  currentSlide: 0,
  init(el) {
    this.handleSlide(el)
  },
  handleSlide(el) {
    let slides = document.getElementsByClassName(`${el}`)
    let current = 0

    setInterval(() => {
      for (var i = 0; i < slides.length; i++) {
        slides[i].style.opacity = 0
      }
      current = (current != slides.length - 1) ? current + 1 : 0
      slides[current].style.opacity = 1
    }, 3000)
  },
  clickNextSlider(el) {
    let slides = document.getElementsByClassName(`${el}`)
    
    for (var i = 0; i < slides.length; i++) {
      slides[i].style.opacity = 0
    }
    this.currentSlide = (this.currentSlide != slides.length - 1) ? this.currentSlide + 1 : 0
    slides[this.currentSlide].style.opacity = 1
  },
  clickPreviousSlider(el) {
    let slides = document.getElementsByClassName(`${el}`)

    for (var i = 0; i < slides.length; i++) {
      slides[i].style.opacity = 0
    }
    this.currentSlide = (this.currentSlide != 0) ? this.currentSlide - 1 : slides.length - 1
    slides[this.currentSlide].style.opacity = 1
  }
}

export default Slider
