import sortDOM from 'sort-dom'

const IS_ONTARIO = document
  .querySelector('body')
  .classList.contains('geolocation-Ontario');

const IS_NORTH_AMERICA = document
  .querySelector('body')
  .classList.contains('NA');

const Contact = {
  init() {
    const hideElement = ['Hong Kong', 'Shen Zhen', '香港', '深圳'];
    this.hideElementByLocation(hideElement);

    if (IS_ONTARIO) {
      this.sortByLocations(document.querySelector('.section-location > .container > .flexbox'))
    }
  },

  hideElementByLocation(elements) {
    if (IS_NORTH_AMERICA) {
      document.querySelectorAll('.location-info').forEach(el => {
        elements.map(element => {
          if (el.querySelector('h2').textContent === element) {
            el.style.display = 'none';
          }
        });
      });
    }
  },

  sortByLocations(parentEl) {
    sortDOM(parentEl, (elA, elB) => {
      const cityA = elA.querySelector('h2').innerHTML
      const cityB = elB.querySelector('h2').innerHTML

      if (cityA === cityB) return 0
      if (cityA === 'Toronto' && cityB === 'North York') return -1
      if (cityA === 'North York' && cityB === 'Toronto') return 1
      if ((cityA === 'Toronto' || cityA === 'North York') && (cityB !== 'Toronto' || cityB !== 'North York')) return -1
      if ((cityA !== 'Toronto' || cityA !== 'North York') && (cityB === 'Toronto' || cityB === 'North York')) return 1
      return 0;
    })
  },

};

export default Contact;
