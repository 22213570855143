import ProjectSlider from '../components/project-slider'
import scrollIt from '../components/smoothScroll'

const Projects = {
  init() {
    let registerBtn = document.querySelector('.register-btn')

    if(registerBtn) {
      registerBtn.addEventListener('click', (e) => {
        e.preventDefault()
        let registerSection = document.querySelector('.register-section')

        scrollIt(registerSection)
      })
    }
    
    ProjectSlider.init('project-slider')
  }
}

export default Projects