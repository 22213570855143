let self

class Accordion {
  constructor(el) {
    self = this
    this.el = el
    this.$accordionItems = document.querySelectorAll(`.${el}-header`)
    this.customerCare = this.customerCare.bind(this)

    this.$accordionItems.forEach(node => {
      node.addEventListener('click', this.handleClick)
    })
  }

  handleClick(e) {
    e.preventDefault()

    let $li = this.parentNode.parentNode.querySelectorAll('li')
    
   $li.forEach(node => {
      node.classList.remove('active')
    })
    this.parentNode.classList.toggle('active')
    // console.log(this.nextElementSibling.children[0].children[0])

    self.customerCare(this)
  }

  customerCare(clickEl) {
    let target = clickEl.nextElementSibling.children[0].children[0]
    let $sidenavLink = document.querySelectorAll('.sidenav-link')
    let $subcategory = document.querySelectorAll('.subcategory')
    let dataTarget = target.getAttribute('data-slug')

    if (this.el === 'sidenav') {
      $sidenavLink.forEach(node => {
        node.classList.remove('active')
      })

      target.classList.add('active')

      $subcategory.forEach(node => {
        let dataSubcat = node.getAttribute('data-subcat')

        dataSubcat === dataTarget ? node.classList.add('active') : node.classList.remove('active')
      })
    }
    
  }
  
}

export default Accordion