import sortDOM from 'sort-dom'
import _ from 'lodash'
import BannerSlider from '../components/banner-slider'
import Dropdown from '../components/dropdown'

const IS_ONTARIO = document.querySelector('body').classList.contains('geolocation-Ontario')
// const IS_ONTARIO = document.querySelector('body').classList.contains('geolocation-BritishColumbia')

const Home = {
  init() {
    this.state = {
      shownPastProjects: 8,
      shownFeaturedProjects: IS_ONTARIO ? 0 : 8,
    }

    this.$pastProjects = document.querySelectorAll('.past-projects .project')
    this.$featuredProjects = document.querySelectorAll('.featured-projects .project')

    this.$btnViewMorePast = document.querySelector('.btn-view-more-past')
    this.$btnViewMoreFeatured = document.querySelector('.btn-view-more-featured')
    this.$btnPastProjects = document.querySelector('.btn-past-projects')
    this.$btnViewLess = document.querySelector('.btn-view-less')

    BannerSlider.init('slider-home')
    this.viewMore()
    this.showMoreFeaturedProjects()
    this.sortProjectsByLocation()

    this.$btnViewLess.addEventListener('click', this.hidePastProjects)

    new Dropdown('Tenant')
    new Dropdown('Homeowner')
  },

  sortProjectsByLocation() {
    // if (!IS_ONTARIO) {
    // // if (!document.querySelector('body').classList.contains('geolocation-BritishColumbia')) {
    //   return
    // }

     //
    if (IS_ONTARIO) {
      this.sortProjects(document.querySelector('.featured-projects'))
    }


    let featuredProjs = document.querySelectorAll('.featured-projects .project');
    let $featuredProjectsContainer = document.querySelector('.featured-projects')
    
    $featuredProjectsContainer.innerHTML = '';

    featuredProjs.forEach((item, index) => {
      if (index < 8) {
        item.style.display = 'block'
      }
      $featuredProjectsContainer.append(item)
    })

    // //
    // if (IS_ONTARIO) {
    //   this.sortProjects(document.querySelector('.featured-projects'))
    //   // this.sortProjects(document.querySelector('.past-projects'))
    // }
  },

  sortProjects(parentEl) {
    sortDOM(parentEl, (elA, elB) => {
      const cityA = elA.querySelector('span.city').innerHTML
      const cityB = elB.querySelector('span.city').innerHTML
      
      if (cityA === cityB) return 0
      if (cityA === cityB) return 0
      if (cityA === 'Toronto' && cityB === 'North York') return -1
      if (cityA === 'North York' && cityB === 'Toronto') return 1
      if ((cityA === 'Toronto' || cityA === 'North York') && (cityB !== 'Toronto' || cityB !== 'North York')) return -1
      if ((cityA !== 'Toronto' || cityA !== 'North York') && (cityB === 'Toronto' || cityB === 'North York')) return 1
      return 0;
    })
  },

  viewMore() {
    let $btnViewMoreFeatured = document.querySelector('.btn-view-more-featured')
    let $pastProjectsContainer = document.querySelector('.past-projects-container')

    $btnViewMoreFeatured.addEventListener('click', () => {
      this.state.shownFeaturedProjects += 10000
      this.showMoreFeaturedProjects()
    })

    this.$btnPastProjects.addEventListener('click', () => {
      document.querySelector('.past-projects-container').style.display = 'block'
      document.querySelector('.btn-past-projects').style.display = 'none'
    })
  },

  showMoreFeaturedProjects() {
    this.$featuredProjects.forEach((el, index) => {
      if (index < this.state.shownFeaturedProjects) {
        el.style.display = 'block'
      }
    })

    if (this.state.shownFeaturedProjects >= this.$featuredProjects.length) {
      this.$btnViewMoreFeatured.style.display = 'none'
      this.$btnPastProjects.style.display = 'inline-block'
    }
  },

  showMorePastProjects() {
    this.$pastProjects.forEach((el, index) => {
      if (index < this.state.shownPastProjects) {
        console.log('hey')
        el.style.display = 'block'
      }
    })

    if (this.state.shownPastProjects >= this.$pastProjects.length) {
      this.$btnViewMorePast.style.display = 'none'
    }
  },

  showPastProjects() {
    document.querySelector('.past-projects').style.display = 'block'
  },
  hidePastProjects() {
    document.querySelector('.past-projects-container').style.display = 'none'
    document.querySelector('.btn-past-projects').style.display = 'inline-block'
  }
}

export default Home