const PublicArt = {
  init() {
    this.handleMasonry()
    this.handleLightbox()
    this.handleViewMore()
  },
  handleMasonry() {
    let $gallery = document.getElementById('gallery')
    let msnry

    imagesLoaded($gallery, () => {
      msnry = new Masonry($gallery, {
        itemSelector: '.gallery-item',
        fitWidth: true
      })
    })
  },
  handleLightbox() {
    lightGallery(document.getElementById('gallery'), {
      subHtmlSelectorRelative: true,
      download: false
    })
  },
  handleViewMore() {
    let $gallery = document.getElementById('sectionGal')
    let $viewMore = document.getElementById('galViewMore')

    $viewMore.addEventListener('click', (e) => {
      e.preventDefault()
      
      $viewMore.classList.add('hide')
      $gallery.classList.add('active')
    })
  }
}

export default PublicArt