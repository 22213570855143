export default {
  init(id) {
    new Vue({
      el: `#${id}`,
      delimiters: ['${', '}'],

      data() {
        return {
          slides: [],
          currentSlide: {},
          currentSlideIndex: 0,
        }
      },

      mounted() {
        this.slides = JSON.parse(this.$el.dataset.slides)
        this.currentSlide = this.slides[0]
      },

      methods: {
        handleClickNext() {
          if (this.currentSlideIndex === this.slides.length - 1) {
            this.currentSlideIndex = 0
          } else {
            this.currentSlideIndex++
          }
          this.currentSlide = this.slides[this.currentSlideIndex]
        },

        handleClickPrev() {
          if (this.currentSlideIndex === 0) {
            this.currentSlideIndex = this.slides.length - 1
          } else {
            this.currentSlideIndex--
          }
          this.currentSlide = this.slides[this.currentSlideIndex]
        },
      },
    })
  }
}
