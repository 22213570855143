const Header = {
  init() {
    this.$header = document.querySelector('.header');
    this.initMobile();
    this.currentPage();
  },

  currentPage() {
    let allLinks = document.querySelector('#navLinks').getElementsByTagName('a')
    let fullPath = location.pathname.split('/')[location.pathname.split('/').length-2]

    for (let i = 0; i < allLinks.length; i++) {
      if (allLinks[i].pathname.split('/')[allLinks[i].pathname.split('/').length-1] === fullPath) {
        allLinks[i].className += " current"
      }
    }
  },

  initMobile() {
    const $hamburger = document.querySelector('#hamburger')
    $hamburger.addEventListener('click', this.handleMenuClick.bind(this))
  },

  handleMenuClick() {
    this.$header.classList.contains('active') ? this.closeMenu() : this.openMenu()
  },

  openMenu() {
    this.$header.classList.add('active')
  },

  closeMenu() {
    this.$header.classList.remove('active')
  },
}

export default Header