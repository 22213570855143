import Dropdown from '../components/dropdown'
import Accordion from '../components/accordion'
import tippy from 'tippy.js'

const Parking = {
  init() {
    new Dropdown('Tenant')
    new Dropdown('Homeowner')
    new Accordion('accordion')

    this.popModal()
  },
  popModal() {
    const template = document.querySelector('#parkingModal')
    const initialText = template.textContent

    const tip = tippy('.parking-link', {
      delay: 100,
      arrow: true,
      size: 'small',
      duration: 500,
      animation: 'scale',
      trigger: 'click',
      html: '#parkingModal',
      zIndex: 888,
      theme: 'light',
      placement: 'right',
      // hideOnClick: false,
      onShow() {
        const content = this.querySelector('.tippy-content')
        let dataParking = this._reference.getAttribute('data-parking')

        if (tip.loading || content.innerHTML !== initialText) return

        tip.loading = true

        fetch(`/wp-content/themes/concord/static/parking/${dataParking}.html`)
          .then(response => {
            return response.text()
          })
          .then(body => {
            content.innerHTML  = body
            tip.loading = false
          })
          .catch(e => {
            content.innerHTML = 'Loading failed'
            tip.loading = false
          })
      },
      onHidden() {
        const content = this.querySelector('.tippy-content')
        content.innerHTML = initialText
      },
      popperOptions: {
        modifiers: {
          preventOverflow: {
            enabled: false
          },
          hide: {
            enabled: false
          }
        }
      }
    })
  }
}

export default Parking 