import Dropdown from '../components/dropdown'
// import Accordion from '../components/accordion'

const Customer = {
  init() {
    new Dropdown('Tenant')
    new Dropdown('Homeowner')
    // new Dropdown('Prompton')
    // new Accordion
  }
}

export default Customer 