const Tabs = {
  init() {
    this.$tabs = document.querySelectorAll('.tab')
    this.$projects = document.querySelectorAll('.commercial-project')
    if (window.location.href.indexOf('zh') > -1) {
      this.selected = 'vancouver-zh'
    } else {
      this.selected = 'vancouver'
    }
    this.citySelected()
    this.attachClick()
  },

  attachClick() {
    Array.from(this.$tabs).forEach(node => {
      node.addEventListener('click', this.handleTabClick.bind(this))
    })
  },

  handleTabClick(e) {
    e.preventDefault()
    
    let tabsArray = Array.from(this.$tabs)
    let $target = e.currentTarget
    if (window.location.href.indexOf('zh') > -1) {
      this.selected = e.target.getAttribute('data-tab')+'-zh'
    } else {
      this.selected = e.target.getAttribute('data-tab')
    }

    tabsArray.forEach(node => {
      node.classList.remove('active')
    })
    $target.classList.add('active')
    this.citySelected()
  },

  citySelected() {
    let projectsArray = Array.from(this.$projects)
    
    projectsArray.forEach(node => {
      let dataCity = node.getAttribute('data-city')
      this.selected === dataCity ? node.classList.add('active') : node.classList.remove('active')
    })
  },
}

export default Tabs