import Accordion from '../components/accordion'

const TenantHomeowner = {
  init() {
    new Accordion('sidenav')
    this.$subcat = document.querySelectorAll('.sidenav-link')

    this.$subcat.forEach(node => {
      node.addEventListener('click', this.handleClick)
    })
  },
  handleClick(e) {
    e.preventDefault()

    let $sidenavLink = document.querySelectorAll('.sidenav-link')
    let $subcategory = document.querySelectorAll('.subcategory')
    let dataSlug = this.getAttribute('data-slug')
    
    $sidenavLink.forEach(node => {
      node.classList.remove('active')
    })
    this.classList.add('active')
    
    $subcategory.forEach(node => {
      let dataSubcat = node.getAttribute('data-subcat')

      dataSubcat === dataSlug ? node.classList.add('active') : node.classList.remove('active')
    })
  }
}

export default TenantHomeowner