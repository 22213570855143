import Slider from '../components/slider'
import ImageComparison from '../components/ImageComparison'

const Communities = {
  init() {
		document.querySelectorAll('.project-image-slideshow').forEach(el => {
			Slider.init(el)
		});
    Slider.init('image-Vancouver')
		Slider.init('image-Toronto')
		this.gallerySliderInit('North_York')
		this.gallerySliderInit('Richmond')
		this.gallerySliderInit('Brentwood')
		this.gallerySliderInit('Metrotown')
		this.gallerySliderInit('Surrey')
		this.gallerySliderInit('Calgary')
		this.gallerySliderInit('London_UK')
		document.addEventListener('DOMContentLoaded', this.imageComparisonInit);
  },
	gallerySliderInit(projectName) {
		document.querySelectorAll('.arrow-left-'+projectName).forEach(el => {
			el.addEventListener('click', (e) => {Slider.clickPreviousSlider('image-'+projectName)})
		})
		document.querySelectorAll('.arrow-right-'+projectName).forEach(el => {
			el.addEventListener('click', (e) => {Slider.clickNextSlider('image-'+projectName)})
		})
	},
	imageComparisonInit() {
		let imageComparisonCollection = document.querySelectorAll('.js-comparison-container');
		for (let i = 0; i < imageComparisonCollection.length; i++) {
		  let imageWidget = imageComparisonCollection[i];
			let images = imageWidget.querySelectorAll('.comparison-image');
			let labels = imageWidget.querySelectorAll('.comparison-label');
		  new ImageComparison({
				container: imageWidget,
				startPosition: imageWidget.getAttribute('start-position'),
				data: [
					{
						image: images[0],
						// label: 'Concord Pacific Place, Current'
						label: labels[0],
					},
					{
						image: images[1],
						// label: 'Concord Pacific Place, Before'
						label: labels[1],
					}
				],
		  });
		}
	  }

}

export default Communities
